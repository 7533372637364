.absolute-zoom-cropper-slider {
    width: 70%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;


    .line {
        background-color: #7F7F7F;
        height: 2px;
        width: 100%;
        display: flex;
        position: relative;
        align-items: center;
    }

    .circle {
        -webkit-appearance: none;
        appearance: none;
        background-clip: padding-box;
        border: 0.2rem solid transparent;
        box-shadow: none;
        transform: scale(2);
        background-color: black;
        height: 8px;
        width: 8px;
        border-radius: 50%;
    }

    .circle:focus {
        transform: scale(2.5);
    }
}