.absolute-zoom-cropper-navigation {
    margin-top: 5px;
    margin-bottom: 5px;
    max-width: 400px;
    width: 100%;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;


    .button {
        border-radius: 25%;
        will-change: transform;
        cursor: pointer;
        width: 24px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: none;
        border: none;
        outline: none;
        transition: transform 0.5s;
        padding: 0;
    }

    .button:hover .button:focus {
        transform: scale(1.1);
    }
}