body {
    padding: 20px;
    margin: 0;
    background: #f6f6f6;
    min-height: calc(100vh);
    background-size: cover;
}

img {
    -webkit-touch-callout: none;
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.main-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    min-width: 50%;
    max-width: 90%;
    max-height: 450px;
}

.main {
    max-width: 800px;
    margin: 0 auto;
}

h1 {
    letter-spacing: -0.04em;
    text-align: center;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 45px;
    color: #2D2D2D;
}

.row {
    width: 100%;
    display: block;
    margin-bottom: 25px;
    justify-content: space-between;
}

.row label {
    width: 100%;
    display: block;
    align-items: center;
    text-align: left;
    justify-content: center;
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: #1d1d1f;
    font-family: 'Inter', system-ui;
    font-style: normal;
    letter-spacing: -0.03em;

}

.row p {
    width: 100%;
    font-size: 15px;
    font-family: 'Inter', system-ui;
    font-style: normal;
    line-height: 20px;
    letter-spacing: -0.03em;
    color: #7F7F7F;
}

.info .field-name {
    font-weight: 600;
    font-size: 15px;
    color: #1d1d1f;
    font-family: 'Inter', system-ui;
    font-style: normal;
    letter-spacing: -0.03em;
    margin-bottom: 10px;
}

.info a {
    cursor: pointer;
    display: block;
    text-decoration: underline;
    margin-bottom: 20px;
    font-size: 15px;
    color: #1d1d1f;
    font-family: 'Inter', system-ui;
    font-style: normal;
    letter-spacing: -0.03em;
}

.info p {
    margin-top: 0;
    font-size: 15px;
    font-family: 'Inter', system-ui;
    font-style: normal;
    line-height: 20px;
    letter-spacing: -0.03em;
    color: #7F7F7F;
}

::placeholder {
    font-family: 'Inter', system-ui;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.03em;
    color: #7F7F7F;
    text-transform: uppercase;
}

.form-control.fw {
    width: 100%;
}

.form-control input {
    background: #FFFFFF;
    border: 1px solid #1d1d1f;
    box-sizing: border-box;
    padding: 14px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    width: 100%;
    outline: none;
    display: block;
    text-transform: uppercase;
}

button[type=submit] {
    padding: 18px 20px;
    background: #1D1D1F;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border: none;
    font-family: Inter, system-ui;
    font-style: normal;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    outline: none;
    width: 100%;
}

.employee-section {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.add-employee-button {
    align-items: center;
    height: 332px;
    width: 217px;
    max-width: 100%;
    background-color: #FFFFFF;
    border: 1px solid rgba(0, 0, 0);
    box-sizing: border-box;
    cursor: pointer;
    justify-content: center;
    margin: 10px;
    padding: calc(.875rem - 1px) calc(1.5rem - 1px);
    text-decoration: none;
    transition: all 100ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
}

.employee {
    position: relative;
    align-items: center;
    justify-content: center;
    height: 392px;
    width: 217px;
    max-width: 100%;
    margin: 10px;
}

.employee-name-input {
    padding: 5%;
    box-sizing: border-box;
    font-size: 15px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 10%;
    width: 100%;
    border: 1px solid rgba(0, 0, 0);
    border-radius: 0;
    text-transform: uppercase;
}

.employee-add-photo-button {
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    cursor: pointer;
    width: 100%;
    height: 332px;
    background: #FFFFFF;
    border: 1px dashed rgba(0, 0, 0);
}

.delete-employee-button {
    position: absolute;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin: 10px;
    bottom: 0;
    margin-bottom: 70px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.2) 0 0 10px;
    background-color: white;
    border: none;
    z-index: 1;
    align-items: center;
    vertical-align: center;
    justify-content: center;
}

.delete-icon {
    width: 40%;
}

.change-photo-button {
    position: absolute;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin: 10px;
    bottom: 0;
    right: 0;
    margin-bottom: 70px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.2) 0 0 10px;
    background-color: white;
    border: none;
    z-index: 1;
    align-items: center;
    vertical-align: center;
    justify-content: center;
}

.change-photo-icon {
    width: 70%;
}

.employee img {
    width: 100%;
    height: 332px;
    background: #FFFFFF;
}

.employee-section img {
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.float-button:hover,
.float-button:focus {
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}

.float-button:hover {
    transform: translateY(-1px);
}

.float-button:active {
    background-color: #F0F0F1;
    box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
    transform: translateY(0);
}

hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    clear: both;
    display: block;
    width: 100%;
    background-color: #000000;
    height: 1px;
}

.no-scroll {
    overflow: hidden;
}

.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: block;
    z-index: 999;
}

.popup {
    overflow-y: auto;
    min-height: 60%;
    max-height: 95%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
    max-width: 800px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.popup-name {
    margin-top: 15px;
    margin-bottom: -5px;
    text-align: center;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: 30px;
    letter-spacing: -0.04em;
    color: #2D2D2D;
}

.close-popup {
    float: right;
    width: 26px;
    height: 26px;
    padding: 0;
    position: sticky;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
}

.popup-wrapper {
    margin: 20px;
    height: fit-content;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    display: flex;
}


.controls {
    max-width: 400px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
}

.controls button {
    letter-spacing: -0.03em;
    font-family: Inter, sans-serif;
    cursor: pointer;
    background: none;
    transition: all 0.1s ease;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    padding: 5%;
    width: 100%;
    border: 1px solid #1D1D1F;
}

.controls .choose-img {
    color: black;
    background: white;
    margin-bottom: 10px;

}

.controls .save-img {
    color: white;
    background: black;
}

.svg-plus {
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.cropper {
    .overlay {
        color: white;
    }
}

.preview-img {
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
}

.cropper-wrapper {
    transition: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 450px;
    height: 100%;
}

.boundary-cropper {
    object-fit: cover;
    width: 100%;
    height: 450px;
}

.cropper-wrapper-navigation {
    width: 100%;
}


.badge-frame {
    pointer-events: none;
    max-width: 100%;
    height: 100%;
    position: absolute;
    z-index: 999;
}

.error-message-photo {
    text-align: center;
}

.instruction-img {
    width: 300px;
    max-width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

}

.error-message {
    display: flex;
    align-items: center;
}